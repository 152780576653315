<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item><a @click="onBack">价格管理</a></a-breadcrumb-item>
        <a-breadcrumb-item>价格详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="info-container">
      <a-form class="receive-form">
        <a-row>
          <a-col>
            <div class="title">
              基本信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-row>
              <a-col>
                <a-form-item label="商品名称">
                  {{ info.skuName ? info.skuName : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item label="制定机构">
                  {{ info.corporationName ? info.corporationName : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item label="实际生效时间">
                  {{
                    info.effectuateStartDate ? info.effectuateStartDate : "--"
                  }}&nbsp;至&nbsp;{{
                    info.effectuateEndDate ? info.effectuateEndDate : "长期"
                  }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item label="优先级">
                  {{ info.priority ? info.priority : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item label="基础价">
                  ￥{{ info.platformPrice ? info.platformPrice : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item>
                  <span slot="label">
                    进货价
                    <a-tooltip
                      title="渠道终端购买价: 基础价+价值包总价-销售包-增值包"
                    >
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </span>
                  ￥{{ info.tradePrice ? info.tradePrice : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col>
                <a-form-item label="产品类型">
                  {{ info.productType ? info.productType : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item label="生效策略名称">
                  {{ info.strategyName || "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item label="作用地区">
                  {{ info.areaOfAction ? info.areaOfAction : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item>
                  <span slot="label">
                    出厂价&nbsp;
                    <a-tooltip title="原厂出厂价">
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </span>
                  ￥{{ info.factoryPrice ? info.factoryPrice : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item>
                  <span slot="label">
                    平台价
                    <a-tooltip
                      title="服务中心/货栈购买价: 基础价+价值包总价-货栈加价-销售包-增值包"
                    >
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </span>
                  ￥{{
                    info.warehousePurchasePrice
                      ? info.warehousePurchasePrice
                      : "--"
                  }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-item>
                  <span slot="label">
                    零售指导价
                    <a-tooltip
                      title="零售指导价: 进货价+销售包+增值包(如果没有配置销售包和增值包,零售指导价是总部配置的)"
                    >
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </span>
                  ￥{{ info.retailPrice ? info.retailPrice : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-form-item label="商品编码">
                {{ info.skuCode ? info.skuCode : "--" }}
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item label="商品图片">
                <div class="img" v-if="info.skuFile">
                  <a
                    :href="imageBaseUrl + info.skuFile.fileUrl"
                    target="_blank"
                  >
                    <img
                      :src="imageBaseUrl + info.skuFile.fileUrl"
                      :alt="info.skuFile.fileName"
                    />
                  </a>
                </div>
                <div v-else>
                  暂无图片
                </div>
              </a-form-item>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
      <a-row>
        <a-col :span="12">
          <div class="title">
            价值包列表
          </div>
          <a-table
            :data-source="tblData"
            :columns="columns"
            :pagination="false"
          ></a-table>
        </a-col>
      </a-row>
    </div>
  </a-layout-content>
</template>

<script>
import { fetchPriceDetail } from "@/services/ProductService";
import { imageBaseUrl } from "@/services/HttpService";

const columns = [
  {
    title: "类型",
    dataIndex: "policyTypeName"
  },
  {
    title: "价值包",
    dataIndex: "value"
  }
];
export default {
  name: "ProductValueDetail",
  data() {
    return {
      tblData: [],
      tblDatas: [],
      imageBaseUrl,
      info: {},
      columns
    };
  },
  mounted() {
    this.onDetail();
  },
  methods: {
    onDetail() {
      fetchPriceDetail(this.$route.params.id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.info.policyListItemVOList.forEach(item => {
            if (item.appendRatio) {
              // 有基础价的比例
              if (item.appendMaxValue) {
                // 存在最大值
                if (this.info.platformPrice) {
                  // 基础价存在
                  if (
                    this.info.platformPrice * item.appendRatio * 0.01 >
                    item.appendMaxValue
                  ) {
                    // 基础价*比列大于最大值
                    item.value =
                      item.appendMaxValue +
                      "元/台 , 基于基础价的" +
                      item.appendRatio +
                      "% , 最大不超过" +
                      item.appendMaxValue +
                      "元";
                  } else {
                    // 小于最大值
                    item.value =
                      (
                        this.info.platformPrice *
                        item.appendRatio *
                        0.01
                      ).toFixed(2) +
                      "元/台 , 基于基础价的" +
                      item.appendRatio +
                      "% , 最大不超过" +
                      item.appendMaxValue +
                      "元";
                  }
                } else {
                  item.value =
                    "基于基础价的" +
                    item.appendRatio +
                    "% , " +
                    "最大不超过" +
                    item.appendMaxValue +
                    "元";
                }
              } else {
                item.value =
                  (this.info.platformPrice
                    ? (
                        this.info.platformPrice *
                        item.appendRatio *
                        0.01
                      ).toFixed(2) + "元/台 , "
                    : "") +
                  (item.appendRatio
                    ? "基于基础价的" + item.appendRatio + "%"
                    : "");
              }
            } else {
              item.value = item.appendFixedValue
                ? item.appendFixedValue + "元/台"
                : "";
            }
          });
          this.tblData = this.info.policyListItemVOList;
        }
      });
    },
    //返回上一级
    onBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}
.special__item /deep/ .ant-form-item-label > label {
  color: #101010;
  font-size: 14px;
  font-weight: bold;
}
/deep/.ant-table-wrapper {
  width: 80%;
}
.title {
  color: #101010;
  font-size: 14px;
  font-weight: bold;
  padding-left: 0;
}

.special__item /deep/ .ant-form-item-label > label::after {
  content: "";
  width: 4px;
}
/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 120px !important;
}
/deep/.ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
}
.img,
.img img {
  width: 100%;
}
</style>
